@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap);
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap");

@import "assets/scss/app.scss";

// @tailwind base;
// @tailwind components;
// @tailwind utilities;

$manrope-slab: "Manrope", sans-serif;
$inter: "Inter", sans-serif;
$mainFont: "Roboto", sans-serif;
$mainColorText: #344767;
$secondColorText: #67748e;
$thirdColorText: #8392ab;
$mainColor: #f8f9fa;
$secondColor: #230b59;
$thirdColor: #f3f6ff;
$theme-color-900: #0448a2;
$theme-color-800: #0d66c1;
$theme-color-700: #1277d3;
$theme-color-600: #1889e6;
$theme-color-500: #1b97f4;
$theme-color-400: #40a6f6;
$theme-color-300: #63b6f6;
$theme-color-200: #90cbf9;
$theme-color-100: #bbdefb;
$theme-color-50: #e3f2fd;
/////////////////////////////////////////////
///
///
///  NEW DESIGN INSPIRED BY MATERIAL UI
///
///
/// /////////////////////////////////////////

.btnPrimary {
  border-radius: 16px !important;
  --si-btn-hover-color: #fff !important;
  --si-btn-active-color: #fff !important;
  --si-btn-hover-bg: #0448a2 !important;
  --si-btn-active-bg: #0448a2 !important;
  --si-btn-hover-border-color: #0448a2 !important;
  --si-btn-active-border-color: #0448a2 !important;
  --si-btn-disabled-color: #fff !important;
  --si-btn-border-color: #1889e6 !important;
  --si-btn-focus-shadow-rgb: 68, 48, 114 !important;
  --si-btn-active-shadow: unset !important;
  --si-btn-disabled-bg: #1889e6 !important;
  --si-btn-disabled-border-color: #1889e6 !important;
  --si-btn-bg: #1889e6 !important;
  --si-btn-color: #ffffff !important;
  box-shadow: rgba(0, 0, 0, 0.11) 0rem 0.25rem 0.4375rem -0.0625rem, rgba(0, 0, 0, 0.07) 0rem 0.125rem 0.25rem -0.0625rem !important;
  // background-image: linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253)) !important;
  outline: 0px !important;
  border: 0px !important;
  border-radius: 0.5rem !important;
  transition: all 150ms ease-in 0s !important;
}
.btnSecondary {
  border-radius: 16px !important;
  --si-btn-hover-color: #fff !important;
  --si-btn-active-color: #fff !important;
  --si-btn-hover-bg: #0448a2 !important;
  --si-btn-active-bg: #0448a2 !important;
  --si-btn-hover-border-color: #0448a2 !important;
  --si-btn-active-border-color: #0448a2 !important;
  --si-btn-disabled-color: #fff !important;
  --si-btn-border-color: #1889e6 !important;
  --si-btn-focus-shadow-rgb: 68, 48, 114 !important;
  --si-btn-active-shadow: unset !important;
  --si-btn-disabled-bg: #1889e6 !important;
  --si-btn-disabled-border-color: #1889e6 !important;
  --si-btn-bg: #1889e6 !important;
  --si-btn-color: #ffffff !important;
  box-shadow: rgba(0, 0, 0, 0.11) 0rem 0.25rem 0.4375rem -0.0625rem, rgba(0, 0, 0, 0.07) 0rem 0.125rem 0.25rem -0.0625rem !important;
  background-image: linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253)) !important;
  outline: 0px !important;
  border: 0px !important;
  border-radius: 0.5rem !important;
  transition: all 150ms ease-in 0s !important;
}

.btnSecondary:hover {
  opacity: 0.8 !important;
}

// Button filter

.card-presentation {
  border: solid 2px $mainColorText !important;
  padding: 10px;
  background: $mainColor;
}

:root {
  --si-blue: #0d6efd;
  --si-indigo: #6610f2;
  --si-purple: #6f42c1;
  --si-pink: #d63384;
  --si-red: #dc3545;
  --si-orange: #fd7e14;
  --si-yellow: #ffc107;
  --si-green: #198754;
  --si-teal: #20c997;
  --si-cyan: #0dcaf0;
  --si-black: #000;
  --si-white: #fff;
  --si-gray: #9397ad;
  --si-gray-dark: #33354d;
  --si-gray-100: #f3f6ff;
  --si-gray-200: #eff2fc;
  --si-gray-300: #e2e5f1;
  --si-gray-400: #d4d7e5;
  --si-gray-500: #b4b7c9;
  --si-gray-600: #9397ad;
  --si-gray-700: #565973;
  --si-gray-800: #33354d;
  --si-gray-900: #0b0f19;
  --si-primary: #230b59;
  --si-secondary: #eff2fc;
  --si-success: #22c55e;
  --si-info: #4c82f7;
  --si-warning: #ffba08;
  --si-danger: #ef4444;
  --si-light: #fff;
  --si-dark: #0b0f19;
  --si-primary-rgb: 35, 11, 89;
  --si-secondary-rgb: 239, 242, 252;
  --si-success-rgb: 34, 197, 94;
  --si-info-rgb: 76, 130, 247;
  --si-warning-rgb: 255, 186, 8;
  --si-danger-rgb: 239, 68, 68;
  --si-light-rgb: 255, 255, 255;
  --si-dark-rgb: 11, 15, 25;
  --si-white-rgb: 255, 255, 255;
  --si-black-rgb: 0, 0, 0;
  --si-body-color-rgb: 86, 89, 115;
  --si-body-bg-rgb: 255, 255, 255;
  --si-font-sans-serif: "Roboto", sans-serif;
  --si-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --si-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --si-body-font-family: var(--si-font-sans-serif);
  --si-body-font-size: 1rem;
  --si-body-font-weight: 400;
  --si-body-line-height: 1.6;
  --si-body-color: $mainColorText;
  --si-body-bg: #fff;
  --si-border-width: 1px;
  --si-border-style: solid;
  --si-border-color: #e2e5f1;
  --si-border-color-translucent: rgba(0, 0, 0, 0.175);
  --si-border-radius: 0.375rem;
  --si-border-radius-sm: 0.25rem;
  --si-border-radius-lg: 0.5rem;
  --si-border-radius-xl: 1rem;
  --si-border-radius-2xl: 2rem;
  --si-border-radius-pill: 50rem;
  --si-link-color: #230b59;
  --si-link-hover-color: #150735;
  --si-code-color: #e3116c;
  --si-highlight-bg: #fff3cd;
}

// Definition des boutons :
.btn:not([class^="btn-outline-"]):not([class*=" btn-outline-"]):not(.btnSecondary):not(.btn-light):not(.btn-link) {
  --si-btn-color: #fff !important;
}

.btn-light {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;
}
.text-muted {
  color: $thirdColorText !important;
  font-size: 1.125rem !important;
}

.btnDisabled {
  cursor: not-allowed !important;
  opacity: 0.6;
}
.btn {
  --si-btn-padding-x: 1.75rem;
  --si-btn-padding-y: 0.625rem;
  --si-btn-font-family: ;
  --si-btn-font-size: 0.875rem;
  --si-btn-font-weight: 600;
  --si-btn-line-height: 1.6;
  --si-btn-color: #565973;
  --si-btn-bg: transparent;
  --si-btn-border-width: 1px;
  --si-btn-border-color: transparent;
  --si-btn-border-radius: 0.375rem;
  --si-btn-hover-border-color: transparent;
  --si-btn-box-shadow: unset;
  --si-btn-disabled-opacity: 0.65;
  --si-btn-focus-box-shadow: 0 0 0 0 rgba(var(--si-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--si-btn-padding-y) var(--si-btn-padding-x);
  font-family: var(--si-btn-font-family);
  font-size: var(--si-btn-font-size);
  font-weight: var(--si-btn-font-weight);
  line-height: var(--si-btn-line-height);
  color: var(--si-btn-color);
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--si-btn-border-width) solid var(--si-btn-border-color);
  border-radius: var(--si-btn-border-radius);
  background-color: var(--si-btn-bg);
  box-shadow: var(--si-btn-box-shadow);
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
.btn:hover {
  color: var(--si-btn-hover-color);
  background-color: var(--si-btn-hover-bg);
  border-color: var(--si-btn-hover-border-color);
}

// Modification CSS
h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 700;
  line-height: 1.375;
  font-size: 1.25rem;
  color: $mainColorText;
}

h1,
.h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 2.5rem;
  }
}

h2,
.h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 2rem;
  }
}

h3,
.h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 1.75rem;
  }
}

h4,
.h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4,
  .h4 {
    font-size: 1.5rem;
  }
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1.25rem;
}

body .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu {
  font-family: $mainFont;
  font-weight: normal;
  color: $mainColorText;
}
body {
  font-family: $mainFont;
  font-weight: normal;
  font-size: 14px;
  color: $mainColorText;
}

.page-body {
  background-color: $mainColor !important;
}
.page-body-wrapper {
  background-color: $mainColor;
}
.page-wrapper .page-body-wrapper .page-sidebar {
  // background: linear-gradient(#393f56, #272b3a);
  background: $mainColor;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu {
  padding: 0px;
}

body .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu {
  padding: 1rem;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a {
  padding: 1rem;
  margin-bottom: 0rem;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a.active {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;

  cursor: pointer;
  border-radius: 0px;
  background: #282b3a;
  color: rgb(255, 255, 255);
  padding: 1rem;
  margin-bottom: 0rem;
  border-left-style: solid;
  border-left-color: #6b6b6b;
  border-width: medium;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a:hover {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 1rem;
  margin-bottom: 0rem;
  cursor: pointer;
  border-radius: 0px;
  background: #282b3a;
  color: rgb(255, 255, 255);
}

.page-wrapper .page-body-wrapper .page-body {
  padding: 0px;
}

///////////////////// FULL CALENDAR EVENT
///
///
///

///////////////////

.badgePriceNeg {
  font-size: 1rem !important;
  font-weight: 500;
  line-height: 1.5rem;
  padding: 0 8px;
  border-radius: 8px;

  color: #a50e0e !important;
  background: #fce8e6 !important;
}
.badgePricePos {
  font-size: 1rem !important;
  font-weight: 500;
  line-height: 1.5rem;
  padding: 0 8px;
  border-radius: 8px;
  color: #006c4b !important;
  background: #e9fff1 !important;
}

.badgePriceNegNoBack {
  font-size: 1rem !important;
  font-weight: 500;
  line-height: 1.5rem;
  padding: 0 8px;
  border-radius: 8px;

  color: #e1191d !important;
  background: #e6f4ea00 !important;
}
.badgePricePosNoBack {
  font-size: 1rem !important;
  font-weight: 500;
  line-height: 1.5rem;
  padding: 0 8px;
  border-radius: 8px;
  color: #13c636 !important;
  background: #e6f4ea00 !important;
}

.card {
  border-radius: 1rem;
  border: 0px solid rgba(0, 0, 0, 0.125);
  box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
}

// CSS elementMenuPageSymbol
.elementMenuPageSymbol {
  background: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
}

.elementMenuPageSymbol > div {
  vertical-align: middle;
  padding: 10px;
  color: #777 !important;
  font-size: medium;
  text-align: center;
  cursor: pointer;
}

.elementMenuPageSymbol > div:hover {
  vertical-align: middle;
  padding: 10px;
  border-bottom: #0a411a;
  font-size: medium;
  color: $secondColor !important;
  cursor: pointer;
}

.elementMenuPageSymbol > div.active {
  vertical-align: middle;
  padding: 10px;
  border-bottom: #2599f3;
  border-bottom-style: solid;
  border-bottom-width: medium;
  font-size: medium;
  color: #2599f3 !important;
  cursor: pointer;
}

.sticky {
  position: sticky;
  top: -70px;
  z-index: 2;
}

.stickyMobile {
  position: sticky;
  top: -100px;
  z-index: 2;
}

// Memo

.memoStyle {
  border-radius: 10px;
  border: 0.5px solid #cececee0;
  padding: 0.5em 0.5em;
  margin: 0.5em 0em;
}

// menu du Data Explorer

.menuDataExplorer > li > a,
.menuDataExplorer:hover > li > a {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.08em;
  line-height: 16px;
  text-transform: uppercase;
  color: $mainColorText !important;
  padding-left: 15px;
}

.navs-icon {
  padding: 0px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.navs-icon li:last-child {
  padding-bottom: 9px;
}

.menuDataExplorerItems {
  color: #6e6e80;
}
.menuDataExplorerItems:hover {
  background: #f7f7f8 !important;
  color: #353740 !important;
}
.menuDataExplorerItems > a {
  padding-left: 15px;
}
.menuDataExplorerItems:hover > a {
  color: #353740 !important;
}

.menuDataExplorer .active {
  background: #2599f3;
}
.menuDataExplorer .active > a {
  color: #ffffff;
}
// Bordr for step

.u-step {
  // border-style: solid;
  border-width: thin;
  position: relative;
  padding: 13px 20px;
  margin: 8px;
  font-size: inherit;
  color: #6b6d6f;
  vertical-align: top;
  background-color: #ffffff;
  border-radius: 0;
  border-radius: 8px;
  box-shadow: 1px 5px 24px rgba(99, 102, 241, 0.06);
}

// BarGraph Mover

.graphBar {
  // border-style: solid;
  width: 45px;
  background: linear-gradient(0deg, #3fb923 0%, #4de22b 100%);
  height: 80px;
  border-radius: 4px 4px 0 0;
  margin: 5px;
  transform: scale(1, -1);
  text-align: center;
  box-shadow: 4px 5px 2px 1px rgba(50, 50, 56, 0.211);
}

.positiveBar {
  // border-style: solid;
  background: linear-gradient(0deg, #448f21 0%, #56c900f5 100%);
  color: #3fb923;
  font-weight: bolder;
}
.negativeBar {
  // border-style: solid;
  background: linear-gradient(0deg, #e1191e 0%, #ed5853 100%);
  color: #e1191e;
  font-weight: bolder;
}

.textGraph {
  margin-top: -50%;
}

.textSymbolGraph {
  // margin-top: 100%;

  color: #ffffff;
}

/////////// Google chart
///
.google-visualization-tooltip {
  display: none;
}

////////////////
///
///

.btn-currency-left {
  border-color: #0081e4;
  background-color: #fff;
  color: #0081e4;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.btn-currency-right {
  border-color: #0081e4;
  background-color: #fff;
  color: #0081e4;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.btn-currency-right:hover {
  opacity: 0.6;
  border-color: inherit;
  background-color: inherit;
}
.btn-currency-left:hover {
  opacity: 0.6;
  border-color: inherit;
  background-color: inherit;
}

.btn-currency-active {
  background-color: #0081e4;
  color: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.btn-currency-active:hover {
  opacity: 0.6;
  border-color: #0081e4;
  color: #fff;
  background-color: #0081e4 !important;
}

// span {
//   font-size: 14px;
// }

// Widget Style
.rdt_Table {
  border-width: 0px;
}

// Button for FIlter in screening

.pillFilter {
  border-radius: 25px;
  background-color: #230b59;
  padding: 5px 10px;
  color: white;
  margin: 0px 5px;
  cursor: pointer;
}

.buttonFilter {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex: 0 0 60px;
  height: 100%;
  padding: 10px;
  font-size: 20px;
  font-weight: 600;
  // border: 1px solid;
  max-width: 100%;
  border-radius: 10px;
  color: $secondColor !important;
  border-radius: 16px;
}

.buttonFilterActif {
  // box-shadow: rgba(9, 30, 66, 0.05) 0px 1px 1px, rgba(9, 30, 66, 0.05) 0px 0px 1px 1px;
  border-color: #1098f7;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  // background-color: #f0f0f0 !important;
}
.buttonFilter:hover {
  border-color: #1098f7;
  background-color: #1097f70f;
}

// Style for notationanalyses

.notation {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex: 0 0 60px;
  height: 100%;
  padding: 10px;
  font-size: 20px;
  font-weight: 600;
  // border: 1px solid;
  max-width: 90%;
  border-radius: 10px;
  color: $secondColor;
}

.notationLight {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex: 0 0 60px;
  height: 100%;
  padding: 5px;
  font-size: 16px;

  // border: 1px solid;
  max-width: 90%;
  border-radius: 10px;
  color: $secondColor;
}

.notationLock {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  background-color: rgba(194, 194, 194, 0.05);
  color: #898989;
  // border-color: #1cce29;
  // background-color: #f6f7fb;
  // opacity: 100%;
  border-color: $secondColor;
}

.notationGreat {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  background-color: rgba(28, 206, 41, 0.05);
  color: #36e18e;
  // border-color: #1cce29;
  // background-color: #f6f7fb;
  // opacity: 100%;
  border-color: $secondColor;
}

.notationNeutral {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  background-color: rgba(255, 200, 0, 0.05);
  color: #ff8800;
  // border-color: #ff8800;
  // background-color: #f6f7fb;
  // opacity: 90%;
  border-color: $secondColor;
}

.notationBad {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  background-color: rgba(255, 17, 17, 0.05);
  color: #f11;
  // border-color: #f11;
  // background-color: #f6f7fb;
  // opacity: 70%;
  border-color: $secondColor;
}

.positiveKeyPoint {
  background-color: rgba(28, 206, 41, 0.05);
  font-weight: 600;
  margin: 10px;
  padding-right: 15px;
  padding-left: 15px;
  width: fit-content;
  font-size: 13px;
  border-radius: 8px;
}
.negativeKeyPoint {
  background-color: rgba(255, 17, 17, 0.05);
  font-weight: 600;
  margin: 10px;
  padding-right: 15px;
  padding-left: 15px;
  width: fit-content;
  font-size: 13px;
  border-radius: 8px;
}

// Gauge

.gauge {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #54626e;
  display: block;
  margin-top: -2px;
  position: absolute;
  transform: translate(-6px);
}
.price-range {
  display: flex;
  justify-content: space-between;
  line-height: 1;
  font-weight: 400;
}
.gauge-bar {
  background-color: #ececec;
  width: 100px;
  border-radius: 4px;
  height: 8px;
  position: relative;
}

.arrow-gauge {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #54626e;
  display: block;
  margin-top: -2px;
  position: absolute;
  transform: translate(-6px);
}

.tableRange {
  // display: flex;
  max-width: 100%;
  width: 80px;
  margin: 0 auto;
}
.instrument-cell {
  flex: 1;
  display: flex;
  justify-content: center;
  width: inherit;
}

.fiftytwo-weeks-progress-bar {
  color: #2c2c2c;
  font-size: 16px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  height: 31px;
  padding-bottom: 10%;
}
.fiftytwo-weeks-progress-bar .price-range {
  display: flex;
  justify-content: space-between;
  line-height: 1;
  font-weight: 400;
}
.fiftytwo-weeks-progress-bar .price-range .low,
.fiftytwo-weeks-progress-bar .price-range .high {
  font-family: DINOT, general-font, Arial, sans-serif;
  direction: ltr;
}
.fiftytwo-weeks-progress-bar .bar-wrap {
  background: linear-gradient(90deg, #f94836, #fdc790, #36ff4f);
  width: 100%;
  border-radius: 4px;
  height: 5px;
  position: relative;
}
.fiftytwo-weeks-progress-bar .measure-arrow {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid #54626e;
  display: block;
  margin-top: -6px;
  position: absolute;
  transform: translate(-6px);
}

// Loader

.loader {
  width: 65px;
  height: 65px;
  border: 5px solid #e3f2fd;
  border-bottom-color: #40a6f6;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Style filter

.listSelectedFilter {
  border-left-style: solid;
  border-width: 2px;
  border-color: #1098f7;
}

// .listSelectedFilterElement {
//   position: relative;
//   content: "";
//   border-bottom: solid;
//   border-width: 2px;
//   border-color: #1098f7;
//   padding-left: 5px;
//   width: 70%;
//   max-width: 20px;
//   bottom: 20px;
// }
// .listSelectedFilterElementFinal {
//   position: relative;
//   content: "";
//   border-left-style: solid;
//   border-bottom: dashed;
//   width: 70%;
//   max-width: 20px;
//   // border-width: 2px;
//   // border-color: #1098f7;
//   padding-left: 5px;
//   margin-top: 10px;
//   bottom: 20px;
// }

// Badge Popular abo

.popularAbo {
  position: absolute;
  top: 2%;
  left: 5%;
  width: fit-content;
  font-size: 0.85em !important;
  font-weight: 600;
  line-height: 1.5rem;
  padding: 0 8px;
  border-radius: 0.25rem;
  color: #22c55e !important;
  background: rgba(34, 197, 94, 0.12) !important;
}

// Ranking

.badgeRankNeutral {
  font-size: 13px !important;
  font-weight: 500;
  line-height: 1.5rem;
  padding: 0 8px;
  border-radius: 8px;

  color: #000000 !important;
  background: #ffffff !important;
}
.badgeRankNeg {
  font-size: 13px !important;
  font-weight: 500;
  line-height: 1.5rem;
  padding: 0 8px;
  border-radius: 8px;

  color: #a50e0e !important;
  background: #fce8e6 !important;
}
.badgeRankPos {
  font-size: 13px !important;
  font-weight: 500;
  line-height: 1.5rem;
  padding: 0 8px;
  border-radius: 8px;
  color: #006c4b !important;
  background: #e9fff1 !important;
}

.verifiedUser {
  display: inline;
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;
  background: #d2f4d3;
  color: #1a7f64;
  padding: 2px 4px 1px;
  border-radius: 3px;
  white-space: nowrap;
  margin-left: 0px;
}

.notVerifiedUser {
  display: inline;
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;
  background: #f9cfcf;
  color: #9d2b2e;
  padding: 2px 4px 1px;
  border-radius: 3px;
  white-space: nowrap;
  margin-left: 0px;
}

.separator {
  margin-right: 10%;
  margin-left: 10%;
  margin-top: 5px;
  margin-bottom: 5px;
  border-bottom: #555 solid 1px;
}

.rdp-day_selected {
  background-color: #2599f3 !important;
}

// Scrollbar

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff6e;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.pill-parameters {
  /* border: solid; */
  margin: 5px;
  padding: 5px 15px !important;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-weight: 500;
  /* font-size: 20px; */
  /* font-weight: 600; */
  max-width: 100%;
  border-radius: 10px;
  color: #230b59;
}

.pill-parameters:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  background-color: rgba(255, 17, 17, 0.05);
  color: #f11;
  border-color: #230b59;
  cursor: pointer;
  transition: 200ms;
}

.sticky-save {
  position: fixed;
  right: 5%;
  bottom: 8%;
  z-index: 10;
  border-radius: 100%;
  height: 60px;
  width: 60px;
  cursor: pointer;
  place-content: center;
  display: flex;
  line-height: 60px;
  background: #0081e4;
  font-weight: 600;
  color: rgb(255, 255, 255);
  animation: glowingEffect 5s linear infinite;
  // box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.sticky-save-hide {
  background: #1098f7;
  transition: opacity 1s ease-out;
  opacity: 0;
}

@keyframes glowingEffect {
  0% {
    box-shadow: none;
  }

  50% {
    box-shadow: #81b5dc2f 0px 0px 6px 10px;
  }
  100% {
    box-shadow: none;
  }
}

.blurred {
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
}

.text-gradient-primary {
  background: linear-gradient(to right, #230b59, #8b5cf6, #d946ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tableETF {
  border-bottom: 1px solid #ddd;
  padding-top: 10px;
  padding-bottom: 10px;
}

.maincolortext {
  color: $mainColorText;
}

a.maincolortext:hover {
  color: $mainColorText !important;
}

.secondcolortext {
  color: $secondColorText !important;
}

a.secondcolortext:hover {
  color: $secondColorText !important;
}

.thirdcolortext {
  color: $thirdColorText;
}

.positive {
  color: #3dae23;
}

.negative {
  color: #e8464a;
}
