.form-control {
  border-radius: 40px !important;
  width: 100%;
  //   height: 40px;
  //   font-size: 1rem;

  color: #495057;
  background-color: #f3f6fc;

  border: none;
}

.form-control:focus {
  color: #495057;
  background-color: #f3f6fc;
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.dropdown-menu {
  background-color: #f3f6fc !important;
}
.dropdown-menu {
  background-color: #f3f6fc !important;
}

.dropdown-item:hover {
  background-color: #e5e9ee;
}
