.header {
  position: fixed;
  top: 5px;
  width: calc(100% - 298px);
  margin: 0px 20px;
  z-index: 2;
}

.header-mobile {
  position: fixed;
  top: 5px;
  width: 96vw;
  margin: 0px 2vw;
  z-index: 2;
}
