.sticky-menu-symbol {
  position: sticky;
  top: 90px;
  z-index: 2;
}

.sticky-menu-symbol-mobile {
  position: fixed;
  bottom: 0px;
  z-index: 2;
  width: 100%;
}
.navig-container {
  display: flex;
  justify-content: center;
  height: 60px;
  margin: 0 8px;
  gap: 8px;
  transition: height 0.3s cubic-bezier(0.2, 0, 0, 1), opacity 0.2s cubic-bezier(0.2, 0, 0, 1);
  // background: #ffffff;
  opacity: 1;
}

.tablist {
  padding: 0px 10px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: #ffffff;
  text-align: center;
  white-space: nowrap;
  overflow: auto;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
}
.tablist-element {
  font-size: 1rem;
  font-weight: 500;

  display: inline-flex;
  position: relative;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 0 20px;

  //   background: #c4e7fe;
  color: black;
  line-height: 44px;
  text-decoration: none;
  text-decoration: initial;
  cursor: pointer;
  vertical-align: middle;
}

.tablist-element-icon {
  background: rgb(233, 236, 239);
  color: rgba(0, 0, 0, 0.54);
  flex-shrink: 0;
  background: rgb(233, 236, 239);
  min-width: 2rem;
  min-height: 2rem;
  border-radius: 0.5rem;
  display: grid;
  place-items: center;
  box-shadow: rgba(20, 20, 20, 0.12) 0rem 0.25rem 0.375rem -0.0625rem, rgba(20, 20, 20, 0.07) 0rem 0.125rem 0.25rem -0.0625rem;
  transition: margin 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 14px;
}

.tablist-element-active .tablist-element-icon {
  background-color: #2599f3;
  color: #ffffff;
}
.tablist-element-active {
  border-bottom: 3px solid #2599f3;
}
.tablist-element-active .tablist-element-text {
  font-weight: 500;
}

.tablist-element:hover .tablist-element-icon {
  background-color: #7abff4;
  color: #ffffff;
}
