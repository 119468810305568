.container-text-menu {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 0;
  margin-left: 0.5rem;
  color: #344767;
  transition: all 150ms ease-in 0s;
}

.card-menu {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 0px;
  color: black;
  padding: 0.8rem 1rem 0.8rem 1rem;
  margin-bottom: 0rem;
  border-width: medium;
  font-size: 1.1rem;
  font-weight: 300;
  transition: all 150ms ease-in 0s;
}

.card-menu-actif .container-text-menu {
  font-weight: 500;
}

.card-menu-actif .container-logo {
  background-image: linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253));
  color: #ffffff;
}

.card-menu:hover .container-logo {
  background-image: linear-gradient(#7abff4, #7abff4);
  color: #ffffff;
  transform: translateY(-3px);
  transition: transform 500ms;
}

.sidebar {
  background-color: none;
  height: 73vh !important;
  padding-top: 1rem;
}

.subtitleMenu {
  margin: 16px 0px 8px 8px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.75rem;
  line-height: 1.25;
  letter-spacing: 0.03333em;
  display: block;
  padding-left: 24px;
  opacity: 0.6;
  text-transform: uppercase;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(52, 71, 103);
  font-weight: 700;
}

.separator-menu {
  flex-shrink: 0;
  border-top: 0px solid rgba(0, 0, 0, 0.12);
  border-right: 0px solid rgba(0, 0, 0, 0.12);
  border-left: 0px solid rgba(0, 0, 0, 0.12);
  background-color: transparent;
  height: 0.0625rem;
  margin: 0rem 0px;
  border-bottom: none;
  opacity: 0.25;
  background-image: linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.5), rgba(52, 71, 103, 0)) !important;
  //   margin-top: 0.6rem;
}

.btn-menu {
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: 0.02857em;
  min-width: 64px;
  width: 100%;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 700;
  border-radius: 0.5rem;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  transition: all 150ms ease-in 0s;
  min-height: 2.5rem;
  box-shadow: rgba(0, 0, 0, 0.11) 0rem 0.25rem 0.4375rem -0.0625rem, rgba(0, 0, 0, 0.07) 0rem 0.125rem 0.25rem -0.0625rem;
  padding: 0.75rem 1.5rem;
  background-image: linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253));
  background-position-y: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  color: rgb(255, 255, 255);
  background-size: 150% !important;
  background-position-x: 25% !important;
}

.btn-menu:hover {
  color: white;
  transform: translateY(-4px);
  opacity: 0.9;
}

.container-logo {
  background: rgb(233, 236, 239);
  color: #344767;
  flex-shrink: 0;
  min-width: 2rem;
  min-height: 2rem;
  border-radius: 0.5rem;
  display: grid;
  place-items: center;
  box-shadow: rgba(0, 0, 0, 0.11) 0rem 0.25rem 0.4375rem -0.0625rem, rgba(0, 0, 0, 0.07) 0rem 0.125rem 0.25rem -0.0625rem;
  font-size: 14px;
  transition: transform 250ms;
}

.text-user-profile {
  color: "white";
  font-size: "16px";
  font-weight: "400";
}

.page-sidebar-mobile {
  display: fixed;
  width: 90% !important;
  top: 80px !important;
  //   height: 100px !important;
}
